import React, { useState, useEffect } from 'react';
import { Input } from './Input';
import { Table } from './Table';
import { ChevronDown, ChevronUp, Search } from 'lucide-react';
import Papa from 'papaparse';

const colorClasses = [
  'bg-blue-100', 'bg-green-100', 'bg-yellow-100', 'bg-red-100', 'bg-indigo-100', 'bg-purple-100', 'bg-pink-100'
];

const batchOrder = ['F24', 'S24', 'W24', 'S23', 'W23', 'S22', 'W22'];

const YCCompaniesTable = () => {
  const [companies, setCompanies] = useState([]);
  const [companyAnalysis, setCompanyAnalysis] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    // Load main company data
    fetch('/yc_companies_with_websites.csv')
      .then(response => response.text())
      .then(csvString => {
        const result = Papa.parse(csvString, { header: true });
        setCompanies(result.data);
      });

    // Load company analysis data
    fetch('/company_analysis.csv')
      .then(response => response.text())
      .then(csvString => {
        const result = Papa.parse(csvString, { header: true });
        const analysisMap = {};
        result.data.forEach(row => {
          analysisMap[row.company_id] = row;
        });
        setCompanyAnalysis(analysisMap);
      });
  }, []);

  const filteredCompanies = companies.filter(company =>
    Object.values(company).some(value =>
      value && value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedCompanies = [...filteredCompanies].sort((a, b) => {
    const indexA = batchOrder.indexOf(a.batch);
    const indexB = batchOrder.indexOf(b.batch);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  const columns = [
    { key: 'batch', header: 'Batch' },
    { key: 'company_name', header: 'Company' },
    { key: 'what_it_does', header: 'What It Does' },
    { key: 'categories', header: 'Category' },
    { key: 'location', header: 'Location' },
  ];

  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const toProperCase = (str) => {
    if (!str) return ''; // Return empty string if str is null or undefined
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const renderAnalysisDetails = (companyId) => {
    const analysis = companyAnalysis[companyId];
    if (!analysis) return null;

    const fields = [
      { key: 'products_and_services', label: 'Products and Services' },
      { key: 'company_positioning', label: 'Company Positioning' },
      { key: 'target_customers', label: 'Target Customers' },
      { key: 'technology_sector_industry', label: 'Technology, Sector and Industry' },
      { key: 'competitors', label: 'Competitors' },
      { key: 'company_mission_and_values', label: 'Company Mission and Values' },
      { key: 'traction_and_achievements', label: 'Traction and Achievements' },
      { key: 'technology_or_innovation', label: 'Technology or Innovation' }
    ];

    const formatContent = (content) => {
      return content.replace(/\*\*(.*?)\*\*/g, '<span class="font-semibold">$1</span>');
    };

    return (
      <div className="grid grid-cols-2 gap-4">
        {fields.map(field => {
          const content = analysis[field.key];
          if (!content) return null;
          return (
            <div key={field.key}>
              <h3 className="font-bold">{field.label}</h3>
              <p dangerouslySetInnerHTML={{ __html: formatContent(content) }}></p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4 bg-gradient-to-r from-cyan-500 to-blue-500 min-h-screen">
      <h1 className="text-4xl font-bold text-white mb-8 text-center">YC Companies Explorer</h1>
      <div className="mb-4 relative">
        <Input
          type="text"
          placeholder="Search companies..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10 pr-4 py-2 w-full rounded-full shadow-lg"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>
      <div className="bg-white rounded-lg shadow-xl overflow-hidden">
        <Table>
          <thead className="bg-gray-100">
            <tr>
              {columns.map((column) => (
                <th key={column.key} className="px-4 py-2 text-left font-semibold text-gray-600">{column.header}</th>
              ))}
              <th className="px-4 py-2"></th>
            </tr>
          </thead>
          <tbody>
            {sortedCompanies.map((company, index) => (
              <React.Fragment key={index}>
                <tr 
                  className={`hover:bg-gray-50 cursor-pointer transition-colors duration-150 ease-in-out ${colorClasses[index % colorClasses.length]}`} 
                  onClick={() => toggleRowExpansion(index)}
                >
                  {columns.map((column) => (
                    <td key={column.key} className="px-4 py-2">
                      {column.key === 'company_name' ? (
                        <a href={company.website_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{company[column.key] || ''}</a>
                      ) : column.key === 'categories' ? (
                        toProperCase(company[column.key])
                      ) : (
                        company[column.key] || ''
                      )}
                    </td>
                  ))}
                  <td className="px-4 py-2">
                    {expandedRow === index ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan={columns.length + 1} className="px-4 py-2 bg-gray-50">
                      <div className="whitespace-pre-wrap">
                        {renderAnalysisDetails(company.company_id)}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default YCCompaniesTable;